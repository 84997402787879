import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// css

import Sidebar from "../../components/Header/sidebar/Sidebar";
import Footer from "../../components/Footer";
import styled from "styled-components";
import MainHeader from "../../components/Header/MainHeader";

const WithSidebar = () => {
  const [sidebar, setSidebar] = useState();
  const location = useLocation();

  return (
    <>
      <WithSidebarLayout className={` d-flex align-items-start`}>
        <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
        <main className={`ms-auto  position-relative`}>
          <MainHeader sidebar={sidebar} setSidebar={setSidebar} />
          <Outlet />
        </main>
      </WithSidebarLayout>
    </>
  );
};

const WithSidebarLayout = styled.div`
  min-height: 100vh;
  background: linear-gradient(318.39deg, #355e3b 15.7%, #0f0b41 84.3%);
  main {
    width: calc(100% - 279px);
  }
  @media (max-width: 992px) {
    main {
      width: 100%;
    }
  }
`;
export default WithSidebar;
