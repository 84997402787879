import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const DefaultGPT = () => {
  return (
    <>
      <div className="sectionHeader pb-3 text-center">
        <h2 className="m-0 fw-normal text-white">Soothesayer GPT</h2>
        <p className="m-0">Ver 4.0 Mar 2024</p>
      </div>
      <Row className="mt-3 justify-content-center">
        <Col md="4" sm="6" className="my-2">
          <CardCstm className="p-3 rounded-4">
            <div className="icnWrp pb-3">{exampleIcn}</div>
            <div className="content">
              <h6 className="m-0 text-white pb-1">Examples</h6>
              <Link to={""} className="underline font-light">
                Explain quantum computing in simple terms →
              </Link>
            </div>
          </CardCstm>
          <ul className="list-unstyled ps-0 mb-0 mt-2">
            <li className="py-1">
              <CardCstm className="p-3 rounded-3">
                <Link to={""} className="underline font-light">
                  Got any creative ideas for a 10 year old’s birthday? →
                </Link>
              </CardCstm>
            </li>
            <li className="py-1">
              <CardCstm className="p-3 rounded-3">
                <Link to={""} className="underline font-light">
                  How do I make an HTTP request in Javascript? →
                </Link>
              </CardCstm>
            </li>
          </ul>
        </Col>
        <Col md="4" sm="6" className="my-2">
          <CardCstm className="p-3 rounded-4">
            <div className="icnWrp pb-3">{copyrightIcn}</div>
            <div className="content">
              <h6 className="m-0 text-white pb-1">Capabilities</h6>
              <p className="m-0 font-light">
                Remembers what user said earlier in the conversation
              </p>
            </div>
          </CardCstm>
          <ul className="list-unstyled ps-0 mb-0 mt-2">
            <li className="py-1">
              <CardCstm className="p-3 rounded-3">
                <p className="m-0 font-light">
                  Allows user to provide follow-up corrections
                </p>
              </CardCstm>
            </li>
            <li className="py-1">
              <CardCstm className="p-3 rounded-3">
                <p className="m-0 font-light">
                  Trained to decline inappropriate requests
                </p>
              </CardCstm>
            </li>
          </ul>
        </Col>
        <Col md="4" sm="6" className="my-2">
          <CardCstm className="p-3 rounded-4">
            <div className="icnWrp pb-3">{limitationIcn}</div>
            <div className="content">
              <h6 className="m-0 text-white pb-1">Limitations</h6>
              <p className="m-0 font-light">
                May occasionally generate incorrect information
              </p>
            </div>
          </CardCstm>
          <ul className="list-unstyled ps-0 mb-0 mt-2">
            <li className="py-1">
              <CardCstm className="p-3 rounded-3">
                <p className="m-0 font-light">
                  May occasionally produce harmful instructions or biased
                </p>
              </CardCstm>
            </li>
            <li className="py-1">
              <CardCstm className="p-3 rounded-3">
                <p className="m-0 font-light">
                  Limited knowledge of world and events after 2021
                </p>
              </CardCstm>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

const CardCstm = styled.div`
  background: rgba(19, 29, 20, 0.57);
  border: 1px solid #5b5b5b;
  backdrop-filter: blur(25.85px);
  border-radius: 12px;
  font-size: 12px;
  line-height: 18px;
  h6 {
    font-weight: 500;
  }
  a {
    text-decoration: underline;
    color: #919191;
  }
`;
export default DefaultGPT;

const exampleIcn = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="#131D14" />
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#6C6C6C" />
    <path
      d="M17 15V19V15ZM15 17H19H15ZM18 29V33V29ZM16 31H20H16ZM25 15L27.286 21.857L33 24L27.286 26.143L25 33L22.714 26.143L17 24L22.714 21.857L25 15Z"
      stroke="#919191"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const copyrightIcn = (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.16699" y="0.5" width="47" height="47" rx="23.5" fill="#131D14" />
    <rect
      x="1.16699"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      stroke="#6C6C6C"
    />
    <path
      d="M25.6181 14.927C25.3191 14.006 24.0161 14.006 23.7161 14.927L22.1971 19.601C22.1317 19.8017 22.0044 19.9765 21.8336 20.1004C21.6628 20.2244 21.4571 20.2911 21.2461 20.291H16.3321C15.3641 20.291 14.9601 21.531 15.7441 22.101L19.7201 24.989C19.8909 25.1132 20.018 25.2882 20.0832 25.4891C20.1484 25.6899 20.1484 25.9062 20.0831 26.107L18.5651 30.781C18.2651 31.702 19.3201 32.469 20.1031 31.899L24.0791 29.011C24.25 28.8868 24.4558 28.8199 24.6671 28.8199C24.8784 28.8199 25.0842 28.8868 25.2551 29.011L29.2311 31.899C30.0141 32.469 31.0691 31.703 30.7691 30.781L29.2511 26.107C29.1858 25.9062 29.1858 25.6899 29.251 25.4891C29.3162 25.2882 29.4433 25.1132 29.6141 24.989L33.5901 22.101C34.3731 21.531 33.9711 20.291 33.0021 20.291H28.0871C27.8762 20.2909 27.6708 20.224 27.5002 20.1001C27.3295 19.9762 27.2025 19.8015 27.1371 19.601L25.6181 14.927Z"
      stroke="#919191"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const limitationIcn = (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.833008"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      fill="#131D14"
    />
    <rect
      x="0.833008"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      stroke="#6C6C6C"
    />
    <path
      d="M24.3334 21V23M24.3334 27H24.3434M17.4054 31H31.2614C32.8014 31 33.7634 29.333 32.9934 28L26.0654 16C25.2954 14.667 23.3714 14.667 22.6014 16L15.6734 28C14.9034 29.333 15.8654 31 17.4054 31Z"
      stroke="#919191"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
