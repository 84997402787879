import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import LocationSearch from "./GoogleSearch";
import logoIcn from "../../../Assets/images/logoIcn.png";

const NewChat = () => {
  const [showLoaction, setShowLocation] = useState();
  return (
    <>
      {/* <div className="sectionHeader pb-3 text-center">
        <h2 className="m-0 fw-normal text-white">What can I help with?</h2>
      </div> */}
      <div className="formWrpper">
        <Form>
          <Row>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <div className="d-flex justify-content-end">
                  <MessageBox className="px-3 py-2 position-relative text-white">
                    asdfasdf
                  </MessageBox>
                </div>
              </div>
              <div className="py-2">
                <div className="d-flex justify-content-start">
                  <MessageBox className="px-3 py-2 position-relative customer d-flex align-items-start gap-10 text-white">
                    <div className="imgWrp flex-shrink-0">
                      <img
                        src={logoIcn}
                        alt=""
                        className="img-fluid rounded-circle object-contain"
                        style={{ height: 30, width: 30 }}
                      />
                    </div>{" "}
                    <div className="contnet">
                      <p className="m-0 py-1">
                        No, the kiara in the URL is not a valid API key—it’s
                        just a placeholder.
                      </p>
                      <p className="m-0 py-1">
                        The API key should be a long string of characters
                        (usually a mix of letters and numbers) provided to you
                        when you sign up for LocationIQ.
                      </p>
                      <pre className="my-2 border border-dark rounded-3 text-dark">
                        <div className="d-flex align-items-center justify-content-end p-3 border-secondary">
                          <Button
                            className="border-0 p-0 d-flex align-items-center fw-sbold"
                            variant="transparent"
                          >
                            <span className="icn me-1">{copyIcn}</span>
                            Copy Code
                          </Button>
                        </div>
                        <div className="content p-3 overflow-auto">
                          <code className="">
                            pk.abcdef1234567890abcdef1234567890
                          </code>
                        </div>
                      </pre>
                      <h4 className="m-0 py-2 fw-bold">What to Do Next:</h4>
                      <ol className="my-2" style={{ fontSize: 15 }}>
                        <li className="py-1">
                          <h6 className="m-0 fw-sbold">
                            Replace kiara in the URL with your actual API key.
                          </h6>
                        </li>
                        <li className="py-1">
                          <h6 className="m-0 fw-sbold">
                            Example Request with Correct API Key:
                          </h6>
                        </li>
                      </ol>
                    </div>
                  </MessageBox>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2 pb-2 sticky-bottom">
              <ChatBox className="position-relative rounded-4 mt-3 p-2">
                {showLoaction && <LocationSearch />}
                <div className="iconWithText position-relative mt-2">
                  <textarea
                    placeholder="Write here..."
                    name=""
                    id=""
                    className="form-control border-0  bg-transparent"
                  ></textarea>
                </div>
                <div className="d-flex align-items-center justify-content-between gap-10 p-2 pb-0">
                  <div className="left d-flex align-items-center gap-10">
                    <Button
                      type="button"
                      onClick={() => setShowLocation(!showLoaction)}
                      variant="transparent"
                      className="border-0 p-0"
                    >
                      {location}
                    </Button>
                    <Button
                      type="button"
                      className="border-0"
                      variant="transparent"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </Button>
                  </div>
                  <Button
                    type="button"
                    variant="transparent"
                    style={{ height: 45, width: 45 }}
                    className="d-flex align-items-center p-2 justify-content-center rounded-circle"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon-2xl"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </Button>
                </div>
              </ChatBox>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const ChatBox = styled.div`
  background: #d5d5d5;
`;
const MessageBox = styled.div`
  background: #141740;
  border-radius: 30px;
  border-bottom-right-radius: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  min-width: 100px;
  &.customer {
    border-radius: 0;
    background: transparent;
  }
  pre {
    background: #d5d5d5;
    code {
      font-size: 15px;
    }
  }
  button {
    font-size: 12px;
  }
`;
export default NewChat;

const location = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z"
      fill="black"
    />
  </svg>
);

const copyIcn = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 5C7 3.34315 8.34315 2 10 2H19C20.6569 2 22 3.34315 22 5V14C22 15.6569 20.6569 17 19 17H17V19C17 20.6569 15.6569 22 14 22H5C3.34315 22 2 20.6569 2 19V10C2 8.34315 3.34315 7 5 7H7V5ZM9 7H14C15.6569 7 17 8.34315 17 10V15H19C19.5523 15 20 14.5523 20 14V5C20 4.44772 19.5523 4 19 4H10C9.44772 4 9 4.44772 9 5V7ZM5 9C4.44772 9 4 9.44772 4 10V19C4 19.5523 4.44772 20 5 20H14C14.5523 20 15 19.5523 15 19V10C15 9.44772 14.5523 9 14 9H5Z"
      fill="currentColor"
    ></path>
  </svg>
);
