import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import user from "../../Assets/images/user.png";
import styled from "styled-components";

const OurCustomer = () => {
  const data = [
    {
      user: user,
      name: "Kipp Bodnar",
      designation: "CMO at HubSpot",
      review:
        "Love how you can take raw footage and turn it into a professional video with AI.",
    },
    {
      user: user,
      name: "Kieran Flanagan",
      designation: "CMO at Zapier",
      review: "It makes video editing much,  much easier.",
    },
    {
      user: user,
      name: "Cameron Baughn",
      designation: "Founder at DesignFriend",
      review:
        "Having spent years editing  video, Capsule is absolutely  mindblowing. 🤯",
    },
    {
      user: user,
      name: "Chris Gehring",
      designation: "Digital and Social Strategist at RedPeg",
      review:
        "Capsule significantly improves  the time it takes to create video.",
    },
  ];
  var settings = {
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    initialSlide: 1,
    arrows: false,
    buttons: false,
  };
  return (
    <>
      <section className="ourCustomer py-5 position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="sectionHeader text-center">
                <h2 className="m-0 fw-normal text-dark">Testimonials</h2>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Slider {...settings}>
                {data.map((item, key) => (
                  <div key={key} className="px-3">
                    <ReviewCard className="border rounded-3 p-3">
                      <p className="m-0 py-2 fw-normal text-muted">
                        {item.review}
                      </p>
                      <div className="info mt-5 d-flex align-items-center gap-10">
                        <div className="imgWrp flex-shrink-0">
                          <img
                            src={item.user}
                            style={{ height: 60, width: 60 }}
                            alt=""
                            className="img-fluid rounded-circle object-fit-cover"
                          />
                        </div>
                        <div className="content">
                          <p className="m-0 text-dark fw-sbold">{item.name}</p>
                          <p className="m-0 text-dark fw-normal text-muted">
                            {item.designation}
                          </p>
                        </div>
                      </div>
                    </ReviewCard>
                  </div>
                ))}
              </Slider>
            </Col>
            <Col lg="12" className="my-2 d-lg-block d-none">
              <Slider rtl="true" {...settings}>
                {data.map((item, key) => (
                  <div key={key} className="px-3">
                    <ReviewCard className="border rounded-3 p-3">
                      <p className="m-0 py-2 fw-normal text-muted">
                        {item.review}
                      </p>
                      <div className="info mt-5 d-flex align-items-center gap-10">
                        <div className="imgWrp flex-shrink-0">
                          <img
                            src={item.user}
                            style={{ height: 60, width: 60 }}
                            alt=""
                            className="img-fluid rounded-circle object-fit-cover"
                          />
                        </div>
                        <div className="content">
                          <p className="m-0 text-dark fw-sbold">{item.name}</p>
                          <p className="m-0 text-dark fw-normal text-muted">
                            {item.designation}
                          </p>
                        </div>
                      </div>
                    </ReviewCard>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const ReviewCard = styled.div`
  font-weight: 500;
  line-height: 20px;
  max-width: 350px;
`;

export default OurCustomer;
