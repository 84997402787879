import axios from "axios";
import { toast } from "react-toastify";

//apply base url for axios
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL = API_BASE_URL + process.env.REACT_APP_API_BASE_URL_EXTENSION;
export const API_VERSION = process.env.REACT_APP_API_VERSION;

const axiosApi = axios.create({
  baseURL: API_URL + API_VERSION,
});

export const updateToken = (token = "") => {
  axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token;
};

// const data = JSON.parse(localStorage.getItem("authUser") || null);
// if (data) {
//   axiosApi.defaults.headers.common["Authorization"] = "Bearer " + data.token;
// }

let token = null;

if (typeof window !== "undefined") {
  token = localStorage.getItem("authToken") || null;
}

export const setAuthInfo = (token = null, userData = null) => {
  if (userData) localStorage.setItem("authUser", JSON.stringify(userData));
  else localStorage.removeItem("authUser");

  if (token) localStorage.setItem("authToken", token);
  else localStorage.removeItem("authToken");
  updateToken(token);
};

updateToken(token || "");

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const LogoutUser = (type = false) => {
  if (!type) {
    toast.error("Please Log In!");
  }
  localStorage.removeItem("authUser");
  localStorage.clear();
  window.location.replace("/login");
};

const getConfig = () => {
  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("authToken") || null;
  }
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};

const checkStatusCode = (code, err) => {
  if (code && [403, 500, 501, 502, 503, 401, 400].includes(code)) {
    // console.log(err,err.response.data.message, "<=========err in if")

    if (code === 401) {
      LogoutUser();
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.response.data.message);
    }
  } else {
    throw new Error(err?.data?.message || err.message || err);
  }
};

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config, ...getConfig() })
    .then((response) => {
      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, data, { ...config, ...getConfig() })
    .then((response) => {
      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config, ...getConfig() })
    .then((response) => {
      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config, ...getConfig() })
    .then((response) => {
      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}
