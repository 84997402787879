import React, { useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DefaultGPT from "./defaultGPT";
import NewChat from "./NewChat";

const Dashboard = () => {
  return (
    <>
      <section className={` position-relative dashboard py-5`}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="9" md="10" className="my-2">
              {/* <DefaultGPT /> */}
              <NewChat />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
