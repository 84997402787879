import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";

// css
import styles from "./EditProfilePop.module.scss";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editProfile } from "../../../lib/redux";

// img

const EditProfilePop = ({ profileEdit, setProfileEdit }) => {
  const dispatch = useDispatch();
  const { loading, user } = useSelector((state) => state.Auth);

  const [formValues, setFormValues] = useState({
    name: "",
    countryCode: "",
    mobileNumber: "",
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        name: user?.name || "",
        countryCode: user?.countryCode || "",
        mobileNumber: user?.mobileNumber || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handlePhoneChange = (value, data) => {
    console.log(data);

    if (data?.dialCode) {
      const mobileNumber = value.replace(`+${data.dialCode}`, "").trim();

      console.log(mobileNumber);

      setFormValues((prevValues) => ({
        ...prevValues,
        countryCode: data?.dialCode,
        mobileNumber: mobileNumber,
      }));
    }
  };
  const handlerSubmit = (event) => {
    event.preventDefault();

    // Merge the form values with existing user data
    const updatedData = {
      name: formValues.name || user?.name, // Use existing value if the field is not updated
      countryCode: formValues.countryCode || user?.countryCode,
      mobileNumber: formValues.mobileNumber || user?.mobileNumber,
    };

    // Check if at least one field is changed (optional, based on requirement)
    if (
      updatedData.name === user?.name &&
      updatedData.countryCode === user?.countryCode &&
      updatedData.mobileNumber === user?.mobileNumber
    ) {
      toast.info("No changes made.");
      return;
    }

    const callBack = (err, res) => {
      if (err) {
        toast.error(err.message);
      } else {
        toast.success(res.message);
        console.log("Profile updated:", res.data);
        setProfileEdit(!profileEdit);
      }
    };

    dispatch(
      editProfile({
        data: updatedData,
        callBack,
      })
    );
  };

  const handleProfileEdit = () => setProfileEdit(!profileEdit);

  return (
    <>
      <Modal
        show={profileEdit}
        className={`${styles.EditProfilePop}  EditProfilePop`}
        onHide={handleProfileEdit}
        backdrop="static"
        keyboard={false}
        centered
        // size="lg"
      >
        <Modal.Body className={`${styles.modalBody} position-relative rounded`}>
          <Button
            onClick={handleProfileEdit}
            className="border-0 p-0 position-absolute"
            variant="transparent"
            style={{ right: 10, top: 0 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 16 15"
              fill="none"
            >
              <g clip-path="url(#clip0_0_6282)">
                <path
                  d="M1.98638 14.906C1.61862 14.9274 1.25695 14.8052 0.97762 14.565C0.426731 14.0109 0.426731 13.1159 0.97762 12.5617L13.0403 0.498994C13.6133 -0.0371562 14.5123 -0.00735193 15.0485 0.565621C15.5333 1.08376 15.5616 1.88015 15.1147 2.43132L2.98092 14.565C2.70519 14.8017 2.34932 14.9237 1.98638 14.906Z"
                  fill="#000"
                />
                <path
                  d="M14.0347 14.9061C13.662 14.9045 13.3047 14.7565 13.0401 14.4941L0.977383 2.4313C0.467013 1.83531 0.536401 0.938371 1.13239 0.427954C1.66433 -0.0275797 2.44884 -0.0275797 2.98073 0.427954L15.1145 12.4907C15.6873 13.027 15.7169 13.9261 15.1806 14.4989C15.1593 14.5217 15.1372 14.5437 15.1145 14.5651C14.8174 14.8234 14.4263 14.9469 14.0347 14.9061Z"
                  fill="#000"
                />
              </g>
              <defs>
                <clipPath id="clip0_0_6282">
                  <rect
                    width="15"
                    height="15"
                    fill="white"
                    transform="translate(0.564453)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Button>
          <div className="top pb-3 d-flex align-items-center border-bottom">
            <h5 className="m-0 fw-sbold text-dark">Edit Profile</h5>
          </div>
          <div className="contentBody py-3">
            <Form onSubmit={handlerSubmit}>
              <Row>
                <Col md="12" className="my-2">
                  <label className="form-label m-0 text-dark">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Col>
                <Col md="12" className="my-2">
                  <label htmlFor="phone" className="form-label m-0 text-dark">
                    Phone
                  </label>
                  <PhoneInput
                    // country="us"
                    value={`${user?.mobileNumber || ""}`}
                    onChange={handlePhoneChange}
                    inputClass="form-control"
                  />
                </Col>
                <Col lg="12" className="my-2 pt-3">
                  <Button
                    disabled={loading}
                    type="submit"
                    className="d-flex w-100 align-items-center justify-content-center commonBtn border-0"
                  >
                    {loading ? "Saving..." : "Save"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProfilePop;
