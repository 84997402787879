import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <>
      <section
        className={` authLayout position-relative py-5 d-flex align-items-center justify-content-center flex-column`}
        style={{
          zIndex: "9",
          lineHeight: "20px",
        }}
      >
        <Container className="">
          <Row className="justify-content-between align-items-center">
            <Outlet />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AuthLayout;
