import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import of1 from "../../Assets/images/of1.png";
import of2 from "../../Assets/images/of2.png";
import of3 from "../../Assets/images/of3.png";

const OurFeature = () => {
  return (
    <>
      <OurFeatureSec className="positon-relative py-5">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="sectionHeader pb-3">
                <h2 className="m-0 fw-normal text-dark">Our Feature</h2>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Row className="justify-content-center">
                <Col md="4" sm="6" className="my-2">
                  <CardCstm className=" rounded-5 p-3 h-100 border">
                    <div className="imgWrp text-center">
                      <img
                        src={of1}
                        alt=""
                        className="img-fluid object-fit-contain"
                        style={{ height: 180 }}
                      />
                    </div>
                    <div className="content p-2">
                      <div className="d-flex align-items-center gap-10 pb-1">
                        <span className="themeGradientClr flex-shrink-0">
                          01.
                        </span>
                        <h5 className="m-0 text-dark">
                          Natural Language Processing
                        </h5>
                      </div>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard
                      </p>
                    </div>
                  </CardCstm>
                </Col>
                <Col md="4" sm="6" className="my-2">
                  <CardCstm className=" rounded-5 p-3 h-100 border">
                    <div className="imgWrp text-center">
                      <img
                        src={of2}
                        alt=""
                        className="img-fluid object-fit-contain"
                        style={{ height: 180 }}
                      />
                    </div>
                    <div className="content p-2">
                      <div className="d-flex align-items-center gap-10 pb-1">
                        <span className="themeGradientClr flex-shrink-0">
                          02.
                        </span>
                        <h5 className="m-0 text-dark">
                          Smart Preference Matching
                        </h5>
                      </div>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard
                      </p>
                    </div>
                  </CardCstm>
                </Col>
                <Col md="4" sm="6" className="my-2">
                  <CardCstm className=" rounded-5 p-3 h-100 border">
                    <div className="imgWrp text-center">
                      <img
                        src={of3}
                        alt=""
                        className="img-fluid object-fit-contain"
                        style={{ height: 180 }}
                      />
                    </div>
                    <div className="content p-2">
                      <div className="d-flex align-items-center gap-10 pb-1">
                        <span className="themeGradientClr flex-shrink-0">
                          03.
                        </span>
                        <h5 className="m-0 text-dark">Real-time Vendor Data</h5>
                      </div>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard
                      </p>
                    </div>
                  </CardCstm>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </OurFeatureSec>
    </>
  );
};

const OurFeatureSec = styled.div``;

const CardCstm = styled.div`
  font-weight: 400;
  h5,
  span {
    font-size: 22px;
  }
  p {
    color: #878787;
    font-size: 12px;
    line-height: 20px;
  }
  @media (max-width: 575px) {
    border-radius: 10px !important;
    h5,
    span {
      font-size: 18px;
    }
  }
`;

export default OurFeature;
