import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import loginImg from "../../../Assets/images/login.png";

// css
import { InputWithLabel } from "../../../layout/Auth/AuthStyled";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../lib/redux";
import { logger } from "../../../helper/common_helper";
import { setAuthInfo } from "../../../helper/api_helper";

const Login = () => {
  const dispatch = useDispatch();
  const { loading, ...auth } = useSelector((state) => state.Auth);

  const navigate = useNavigate();
  const [pass, setPass] = useState();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const handlePass = () => {
    setPass(!pass);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handlerSubmit = (event) => {
    event.preventDefault();

    try {
      const { email, password } = formValues;

      // Validation check: Ensure the 'name' is not empty
      if (!email || !password) {
        toast.error("All fields are required");
        return;
      }

      const callBack = (err, res) => {
        if (err) {
          console.log("Error:", err.message);
          toast.error(err.message);
        } else {
          logger(res, "asdfasdfjasw");
          toast.success(res.message);
          setAuthInfo(res.token, res.data);
          navigate("/dashboard");
        }
      };

      // Dispatch the action to register the user
      dispatch(
        login({
          data: { email, password },
          callBack,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
      toast.error("An error occurred");
    }
  };
  return (
    <>
      <Col lg="5" md="6" className="my-2">
        <div
          className={` formWrpper position-relative `}
          style={{ maxHeight: "85vh" }}
        >
          <div className="formInner position-relative px-lg-3">
            <div className="w-100 inner">
              <div className="top">
                <h2 className="m-0 fw-sbold pb-1 text-dark">Login</h2>
                <p className="m-0 py-1 fw-normal text-muted">
                  Login to access your Soothesayer account
                </p>
              </div>
              <div className="contentBody py-2">
                <Form onSubmit={handlerSubmit}>
                  <Row>
                    <Col lg="12" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor=""
                          className="form-label position-absolute bg-white px-1"
                        >
                          Email
                        </label>
                        <input
                          onChange={handleChange}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="john.doe@gmail.com"
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>
                    <Col lg="12" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor=""
                          className="form-label position-absolute bg-white px-1"
                        >
                          Password
                        </label>
                        <input
                          onChange={handleChange}
                          id="password"
                          name="password"
                          type="password"
                          placeholder="•••••••••••••••••••••••••"
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>
                    <Col lg="12" className="my-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left d-flex align-items-center gap-10">
                          <input type="checkbox" className="form-check" />
                          <p className="m-0">Remember me</p>
                        </div>
                        <Link to={"/forgot-password"} className="themeClr">
                          Forgot Password
                        </Link>
                      </div>
                    </Col>
                    <Col lg="12" className="my-2">
                      <div className="btnWrpper mt-3">
                        <Button
                          disabled={loading}
                          type="submit"
                          className="d-flex align-items-center justify-content-center commonBtn w-100"
                        >
                          {loading && (
                            <span className="loading loading-spinner"></span>
                          )}
                          {!loading && "Login"}
                        </Button>
                        <div className="mt-3 text-center">
                          <p className="m-0">
                            Don’t have an account?{" "}
                            <Link to="/signup" className="themeClr">
                              Sign up
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md="6" className="imgWrpper d-none d-lg-block">
        <div className="rounded-3 p-3" style={{ background: "#F4F4F4" }}>
          <img src={loginImg} alt="" className="img-fluid w-100 rounded-3" />
        </div>
      </Col>
    </>
  );
};
export default Login;
