/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../../helper/url_helper";
import { post, updateToken, get, put } from "../../../../helper/api_helper";
import moment from "moment";
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const login = createAsyncThunk(
  "login",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.LOGIN_USER, data);
      updateToken(response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const registerEmailAndOtpSend = createAsyncThunk(
  "registerEmailAndOtpSend",
  async (
    {
      name, // Send the combined name
      email,
      password,
      countryCode,
      mobileNumber,
      confirmPassword,
      callBack,
    },
    Thunk
  ) => {
    try {
      const data = {
        name, // Send the combined name
        email,
        password,
        countryCode,
        mobileNumber,
        confirmPassword,
      };
      const response = await post(URL.REGISTER, data);

      // Optionally, update the token or perform any other actions
      updateToken(response.data.token);
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...response.data,
          expireTime: moment().add(2, "minutes").valueOf(),
        })
      );
      // Invoke the callback with success response
      if (callBack) {
        callBack(null, response);
      }

      // Return response data along with the form data
      return {
        ...response.data,
        ...data,
        expireTime: moment().add(2, "minutes").valueOf(),
      };
    } catch (error) {
      // Invoke the callback with the error
      if (callBack) {
        callBack(error);
      }
      // Reject the promise with error details
      return Thunk.rejectWithValue(error);
    }
  }
);
export const editProfile = createAsyncThunk(
  "editProfile",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await put(URL.EDIT_PROFILE, data);
      updateToken(response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const socialLogin = createAsyncThunk(
  "socialLogin",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.SOCIAL_LOGIN, data);
      updateToken(response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ data, callBack }, Thunk) => {
    try {
      console.log(data, "dataPayload");
      const response = await post(URL.VERIFY_EMAIL, data);
      updateToken(response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  "registerUser",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.REGISTER_PASS, data);
      updateToken(response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const forgotPasswordEmailVerifyOTP = createAsyncThunk(
  "forgotPasswordEmailVerifyOTP",
  async ({ data, callBack }, Thunk) => {
    try {
      console.log(data, "otpcheck1");

      const response = await post(URL.FORGOT_PASSWORD_OTP, data);
      updateToken(response.data.token);
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...response.data,
          expireTime: moment().add(2, "minutes").valueOf(),
        })
      );
      callBack && callBack(null, response);
      return {
        ...response.data,
        ...data,
        expireTime: moment().add(2, "minutes").valueOf(),
      };
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const verifyForgotEmail = createAsyncThunk(
  "verifyForgotEmail",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.VERIFY_FORGOT_EMAIL, data);
      updateToken(response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const resetForgotPassword = createAsyncThunk(
  "resetForgotPassword",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.RESET_FORGOT_PASSWORD, data);
      updateToken(response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.CHANGE_PASSWORD, data);
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async ({ data, callBack }, Thunk) => {
    try {
      // API call for logout
      const response = await post(URL.LOGOUT, data);

      // Remove auth info from localStorage
      localStorage.removeItem("authUser");
      localStorage.removeItem("authToken");

      // Invoke callback for success
      if (callBack) callBack(null, response);

      // Return the response data to the state
      return response.data;
    } catch (error) {
      console.error("Logout error:", error);

      // Invoke callback for error
      if (callBack) callBack(error);

      // Reject with value for error handling in Redux
      return Thunk.rejectWithValue(error);
    }
  }
);

export const profileDetails = createAsyncThunk(
  "profileDetails",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await get(URL.PROFILE_DETAILS, data);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const delProfile = createAsyncThunk(
  "delProfile",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.DELETE_PROFILE, data);
      localStorage.removeItem("authUser");
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

//file upload
export const fileUpload = createAsyncThunk(
  "fileUpload",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await post(URL.FILE_UPLOAD, data);
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
