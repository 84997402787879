import Login from "./AuthPages/Login/Index";
import Dashboard from "./SideTabPages/Dashboard";
import LandingPage from "./LandingPage";
import SignUp from "./AuthPages/Signup/Index";
import MyProfile from "./SideTabPages/MyProfile";
import ForgotPassword from "./AuthPages/forgotPassword";
import VerifyCode from "./AuthPages/VerifyCode";
import ResetPassword from "./AuthPages/ResetPassword";

export const routes = [
  { path: "/login", component: <Login /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/verify-code", component: <VerifyCode /> },
  { path: "/reset-password", component: <ResetPassword /> },
];
export const publicRoutes = [
  { path: "/landing-page", component: <LandingPage /> },
];
export const privateRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/my-profile", component: <MyProfile /> },
];
