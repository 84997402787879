export const LOGIN_USER = "/user/login";
export const REGISTER = "/user/register";
export const LOGOUT = "/user/logout";
export const PROFILE_DETAILS = "/user/profile";
export const EDIT_PROFILE = "/user/edit";
export const VERIFY_EMAIL = "/user/forgotPassword";
export const FORGOT_PASSWORD_OTP = "/user/verifyPassOTP";
export const RESET_FORGOT_PASSWORD = "/user/resetPassword";

export const SOCIAL_LOGIN = "/user/social_login";
export const VERIFY_FORGOT_EMAIL = "/user/verifyPassOTP";
export const REGISTER_PASS = "/user/register_pass";

export const ADD_PAYMENT_METHOD = "/user/paymentMethod/add";
export const GET_PAYMENT_METHOD = "/user/paymentMethod/get";
export const MARK_DEFAULT_PAYMENT_METHOD = "/user/paymentMethod/markDefault";

export const BECOME_AFFILIATE_REQUEST = "/user/affiliate/request/add";
export const LIST_AFFILIATE_REQUEST = "/user/affiliate/request/list";
export const LIST_AFFILIATE_CLAIM_REQUEST =
  "/user/affiliate/request/claim/list";
export const AFFILIATE_CLAIM_REQUEST = "/user/affiliate/request/claim/add";

export const GET_ADD_CHARACTERS_FIELDS = "/user/character/fields";
export const ADD_CHARACTER = "/user/character/add";
export const GET_AI_EXPLORE_CHARACTERS = "/user/character/explore";
export const GET_CONTENT = "/user/content/get";
export const GET_SUBSCRIPTIONS_LIST = "/user/subscription/list";
export const CREATE_SUBSCRIPTION = "/user/subscription/create_subscription";
export const APPLY_COUPON = "/user/subscription/applyCoupon";
export const VERIFY_COUPON = "/user/subscription/verifyCoupon";
export const USER_SUBSCRIPTION_HISTORY = "/user/subscription/history";
export const USER_INVOICE_HISTORY = "/user/subscription/invoice";
export const USER_SUBSCRIPTION_LIST = "/user/subscription/my_list";
export const GET_HOME_PAGE_CONTENT = "/user/content/home";
export const GET_REVIEWS = "/user/content/review";
export const GET_PAGES = "/user/content/pages";
export const CONTACT_US = "/user/contactUs";

export const DELETE_PROFILE = "/user/del";

export const CHANGE_PASSWORD = "/user/editPwd";
export const GET_CHARACTERS = "/user/character/get";
export const GET_PRIVATE_CHARACTERS = "/user/character/personal";
export const GET_SINGLE_CHARACTER_DETAILS = "/user/character/singleCharacter";
export const UNLOCK_CHARACTER = "/user/character/unlock_img";

export const GET_CHAT_LIST = "/chat/list";
export const GET_CHAT_DETAILS = "/chat";
export const CREATE_CHAT = "/chat/create";

export const FILE_UPLOAD = "/user/upload_img";
