import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { profileDetails } from "../../../lib/redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import dummyUser from "../../../Assets/images/dummyUser.jpeg";
import EditProfilePop from "../../../components/Modals/EditProfilePop";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const [profileEdit, setProfileEdit] = useState();

  const fetchData = async () => {
    try {
      const callBack = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          // toast.success(res?.data?.message);
        }
      };
      dispatch(profileDetails({ data: {}, callBack }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleProfileEdit = () => setProfileEdit(!profileEdit);

  // console.log(user, "asdfasdfaproifle");
  return (
    <>
      <EditProfilePop
        profileEdit={profileEdit}
        setProfileEdit={setProfileEdit}
      />
      <section className={` position-relative py-5`}>
        <Container>
          <Row className="justify-content-center">
            {/* <Col lg="12" className="my-2">
              <div className="sectionHeader pb-3 ">
                <h2 className="m-0 fw-normal text-white">My Profile</h2>
              </div>
            </Col> */}
            <Col lg="12" className="my-2">
              <FormWrpper
                className="mx-auto rounded-4 p-lg-4 p-3"
                style={{ maxWidth: 750 }}
              >
                <Form>
                  <div className="top pb-3">
                    <div className="sectionHeader pb-3 d-flex align-items-center justify-content-between">
                      <h2 className="m-0 fw-normal text-white">My Profile</h2>
                      <Button
                        onClick={handleProfileEdit}
                        className="border-0 p-0 text-white"
                        variant="transparent"
                      >
                        {editIcn}
                      </Button>
                    </div>
                  </div>
                  <Row>
                    {/* <Col lg="12" className="my-2">
                      <div className="text-center">
                        <div className="imgWrp">
                          <img
                            src={
                              user?.profileImage
                                ? user?.profileImage
                                : dummyUser
                            }
                            alt=""
                            style={{ height: 100, width: 100 }}
                            className="img-fluid rounded-circle bg-light"
                          />
                        </div>
                      </div>
                    </Col> */}
                    <Col sm="6" className="my-2">
                      <label htmlFor="" className="form-label mb-0">
                        Name
                      </label>
                      <input
                        type="text"
                        value={user?.name}
                        className="form-control"
                        readOnly
                      />
                    </Col>
                    <Col sm="6" className="my-2">
                      <label htmlFor="" className="form-label mb-0">
                        Phone Number
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={user?.mobileNumber}
                        inputClass="form-control"
                        readOnly
                      />
                    </Col>
                    <Col sm="6" className="my-2">
                      <label htmlFor="" className="form-label mb-0">
                        Email
                      </label>
                      <input
                        type="email"
                        value={user?.email}
                        className="form-control"
                        readOnly
                      />
                    </Col>
                  </Row>
                </Form>
              </FormWrpper>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
const FormWrpper = styled.div`
  background: #141740;
`;

export default MyProfile;

const editIcn = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 4.99998L19 7.99998M20.385 6.58499C20.7788 6.19114 21.0001 5.65697 21.0001 5.09998C21.0001 4.543 20.7788 4.00883 20.385 3.61498C19.9912 3.22114 19.457 2.99988 18.9 2.99988C18.343 2.99988 17.8088 3.22114 17.415 3.61498L9 12V15H12L20.385 6.58499Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
