import React, { useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LogoutUser } from '../../helper/api_helper';
// css

// img
import logo from "../../Assets/images/logo.png";
import user from "../../Assets/images/user.png";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../lib/redux";
import { setAuthInfo } from "../../helper/api_helper";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.Auth);
  // console.log(user, "yserdertail");

  const handleLogout = async () => {
    try {
      dispatch(logout());
      toast.success("Logout Succesfully");
      setAuthInfo();
      LogoutUser(true);
    } catch (err) {
      console.log(err, "error=====><");
      if (err && err.data && err.data.message) {
        localStorage.clear();
        // toast.error(err.data.message);
      }
    }
  };

  return (
    <>
      <SiteHeader
        className={`  siteHeader  position-absolute top-0 py-2 w-100`}
        style={{ zIndex: 99 }}
      >
        <Container>
          <Navbar className="d-flex align-items-center justify-content-between gap-10">
            <Link to={"/landing-page"} className="logo">
              <img src={logo} alt="" className="img-fluid object-contain" />
            </Link>
            <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10">
              {isAuthenticated ? (
                <>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="link profileLink border-0 p-0 d-flex align-items-center"
                    >
                      <div className="rounded text-capitalize fw-bold text-white icn d-flex align-items-center justify-content-center me-2">
                        {user?.name
                          ?.split(" ")
                          .slice(0, 2)
                          .map((word) => word[0])
                          .join("")}
                      </div>
                      <p className="m-0 text-white text-capitalize">
                        {user?.name}
                      </p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="px-2">
                      <ul className="list-unstyled ps-0 mb-0">
                        <li className="">
                          <Link to={"/dashboard"} className="text-dark">
                            Dashboard
                          </Link>
                        </li>
                        <li className="">
                          <Link to={"/my-profile"} className="text-dark">
                            My Profile
                          </Link>
                        </li>
                        <li className="">
                          <Button
                            to={""}
                            onClick={handleLogout}
                            variant="transparent"
                            className="text-dark border-0 p-0"
                          >
                            Logout
                          </Button>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <li>
                    <BtnLink
                      to="/login"
                      className="d-flex border-0 align-items-center justify-content-center btn bg-transparent text-white fw-sbold"
                    >
                      Login
                    </BtnLink>
                  </li>
                  <li>
                    <BtnLink
                      to="/signup"
                      className="active d-flex border-0 align-items-center justify-content-center btn bg-transparent text-white fw-sbold"
                    >
                      Signup
                    </BtnLink>
                  </li>
                </>
              )}
            </ul>
          </Navbar>
        </Container>
      </SiteHeader>
    </>
  );
};

const SiteHeader = styled.header`
  .logo {
    img {
      height: 80px;
    }
  }
  .profileLink {
    font-size: 12px;
    .icn {
      width: 28px;
      height: 28px;
      background: linear-gradient(91.16deg, #78fa8b 1.03%, #0000ff 15.3%);
    }
  }
  .dropdown-menu {
    font-size: 12px;
    left: unset !important;
    right: 0 !important;
    a,
    button {
      transition: 0.4s;
      font-size: 12px;
      font-weight: 500;
      &:hover {
        color: #0000ff !important;
      }
    }
  }
  @media (max-width: 575px) {
    .logo {
      img {
        height: 50px;
      }
    }
  }
`;

const BtnLink = styled(Link)`
  transition: 0.4s;
  min-width: 99px;
  height: 46px;
  border-radius: 6px;
  font-size: 13px;
  &:hover,
  &.active {
    background: linear-gradient(318.39deg, #355e3b 15.7%, #0f0b41 84.3%);
  }
`;

export default Header;
