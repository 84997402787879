import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import hiw from "../../Assets/images/hiw.png";

const HowItWork = () => {
  return (
    <>
      <HowItWorkSec className="position-relative py-5">
        <Col
          lg="7"
          className="py-5 position-absolute h-100 text-end imgWrpper"
          style={{ right: 0, top: 0 }}
        >
          <img src={hiw} alt="" className="img-fluid h-100 object-fit-right" />
        </Col>
        <Container>
          <Row>
            <Col lg="5" className="my-2">
              <div className="sectionHeader pb-3">
                <h2 className="m-0 fw-normal text-white">How It Works</h2>
              </div>
              <div className="contentList pt-3">
                <ul className="list-unstyled ps-0 mb-0">
                  <li className="py-3 d-flex align-items-start gap-10">
                    <span className="icn flex-shrink-0 themeGradientClr position-relative">
                      01
                    </span>
                    <div className="content">
                      <h6 className="m-0 text-white pb-1">
                        Smart Preference Extraction
                      </h6>
                      <p className="m-0 fw-normal">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard
                      </p>
                    </div>
                  </li>
                  <li className="py-3 d-flex align-items-start gap-10">
                    <span className="icn flex-shrink-0 themeGradientClr position-relative">
                      02
                    </span>
                    <div className="content">
                      <h6 className="m-0 text-white pb-1"> Vendor Search</h6>
                      <p className="m-0 fw-normal">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard
                      </p>
                    </div>
                  </li>
                  <li className="py-3 d-flex align-items-start gap-10">
                    <span className="icn flex-shrink-0 themeGradientClr position-relative">
                      03
                    </span>
                    <div className="content">
                      <h6 className="m-0 text-white pb-1">
                        Personalized Rating
                      </h6>
                      <p className="m-0 fw-normal">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </HowItWorkSec>
    </>
  );
};

const HowItWorkSec = styled.div`
  background: linear-gradient(318.39deg, #355e3b 15.7%, #0f0b41 84.3%);
  backdrop-filter: blur(63px);
  color: #8e949f;
  line-height: 22px;
  ul {
    li {
      span {
        font-size: 24px;
        top: 5px;
      }
      h6 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
      }
      p {
        font-size: 13px;
      }
    }
  }
  @media (max-width: 992px) {
    .imgWrpper {
      position: unset !important;
      padding-top: 0 !important;
    }
  }
`;

export default HowItWork;
