import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../components/Header";

import Sidebar from "../../components/Header/sidebar/Sidebar";
import Footer from "../../components/Footer";

const MainLayout = () => {
  const [sidebar, setSidebar] = useState();
  const location = useLocation();

  return (
    <>
      <div className={``}>
        {/* <Sidebar sidebar={sidebar} setSidebar={setSidebar} /> */}
        <main className={` position-relative`}>
          <Header sidebar={sidebar} setSidebar={setSidebar} />
          <Outlet />
          <Footer />
        </main>
      </div>
    </>
  );
};
export default MainLayout;
