import React from "react";
import {
  Button,
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import styled from "styled-components";
import logoIcn from "../../Assets/images/logoIcn.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logout } from "../../lib/redux";
import { LogoutUser } from "../../helper/api_helper";
import { setAuthInfo } from "../../helper/api_helper";
const MainHeader = ({ sidebar, setSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  // console.log(user, "yserdertail");

  const handleLogout = async () => {
    try {
      dispatch(logout());
      toast.success("Logout Succesfully");
      setAuthInfo();
      LogoutUser(true);
    } catch (err) {
      console.log(err, "error=====><");
      if (err && err.data && err.data.message) {
        localStorage.clear();
        // toast.error(err.data.message);
      }
    }
  };
  return (
    <>
      <SiteHeader className=" sticky-top py-2" style={{ zIndex: 9999 }}>
        <Navbar expand="lg" className="">
          <Container>
            <Button
              onClick={() => setSidebar(!sidebar)}
              className="border-0 p-0 d-lg-none"
              variant={"transparent"}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 6.99998C2.36739 6.99998 2.24021 6.9473 2.14645 6.85353C2.05268 6.75976 2 6.63258 2 6.49998V2.50098C2 2.36837 2.05268 2.24119 2.14645 2.14742C2.24021 2.05366 2.36739 2.00098 2.5 2.00098H6.5C6.63261 2.00098 6.75979 2.05366 6.85355 2.14742C6.94732 2.24119 7 2.36837 7 2.50098V6.49998C7 6.63258 6.94732 6.75976 6.85355 6.85353C6.75979 6.9473 6.63261 6.99998 6.5 6.99998H2.5ZM9.5 6.99998C9.36739 6.99998 9.24021 6.9473 9.14645 6.85353C9.05268 6.75976 9 6.63258 9 6.49998V2.50098C9 2.36837 9.05268 2.24119 9.14645 2.14742C9.24021 2.05366 9.36739 2.00098 9.5 2.00098H13.499C13.6316 2.00098 13.7588 2.05366 13.8526 2.14742C13.9463 2.24119 13.999 2.36837 13.999 2.50098V6.49998C13.999 6.63258 13.9463 6.75976 13.8526 6.85353C13.7588 6.9473 13.6316 6.99998 13.499 6.99998H9.5ZM2.5 14C2.36739 14 2.24021 13.9473 2.14645 13.8535C2.05268 13.7598 2 13.6326 2 13.5V9.49998C2 9.36737 2.05268 9.24019 2.14645 9.14642C2.24021 9.05265 2.36739 8.99998 2.5 8.99998H6.5C6.63261 8.99998 6.75979 9.05265 6.85355 9.14642C6.94732 9.24019 7 9.36737 7 9.49998V13.5C7 13.6326 6.94732 13.7598 6.85355 13.8535C6.75979 13.9473 6.63261 14 6.5 14H2.5ZM9.5 14C9.36739 14 9.24021 13.9473 9.14645 13.8535C9.05268 13.7598 9 13.6326 9 13.5V9.49998C9 9.36737 9.05268 9.24019 9.14645 9.14642C9.24021 9.05265 9.36739 8.99998 9.5 8.99998H13.499C13.6316 8.99998 13.7588 9.05265 13.8526 9.14642C13.9463 9.24019 13.999 9.36737 13.999 9.49998V13.5C13.999 13.6326 13.9463 13.7598 13.8526 13.8535C13.7588 13.9473 13.6316 14 13.499 14H9.5Z"
                  fill="white"
                />
              </svg>
            </Button>
            <Link className="p-0" to="/landing-page">
              <img
                src={logoIcn}
                alt=""
                className="img-fluid object-fit-contain"
              />
            </Link>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="border-0 p-0"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto gap-10">
                <div className="px-2">
                  <Link to="" className="link align-items-center">
                    Help <span className="icn ms-1">{help}</span>
                  </Link>
                </div>
                <div className="px-2">
                  <Link to="" className="link d-flex align-items-center">
                    API <span className="icn ms-1">{link}</span>
                  </Link>
                </div>
                <div className="px-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="link profileLink border-0 p-0 d-flex align-items-center"
                    >
                      <div className="rounded text-capitalize fw-bold text-white icn d-flex align-items-center justify-content-center me-2">
                        {user?.name
                          ?.split(" ")
                          .slice(0, 2)
                          .map((word) => word[0])
                          .join("")}
                      </div>
                      <p className="m-0 text-white text-capitalize">
                        {user?.name}
                      </p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="px-2">
                      <ul className="list-unstyled ps-0 mb-0">
                        <li className="">
                          <Link to={"/dashboard"} className="text-dark">
                            Dashboard
                          </Link>
                        </li>
                        <li className="">
                          <Link to={"/my-profile"} className="text-dark">
                            My Profile
                          </Link>
                        </li>
                        <li className="">
                          <Button
                            to={""}
                            onClick={handleLogout}
                            variant="transparent"
                            className="text-dark border-0 p-0"
                          >
                            Logout
                          </Button>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </SiteHeader>
    </>
  );
};

const SiteHeader = styled.header`
  background: #141740;
  border-bottom: 1px solid #393939;
  backdrop-filter: blur(5px);
  color: #767676;
  .dropdown-menu {
    font-size: 12px;
    left: unset !important;
    right: 0 !important;
    a,
    button {
      transition: 0.4s;
      font-size: 12px;
      font-weight: 500;
      &:hover {
        color: #0000ff !important;
      }
    }
  }
  .link {
    color: #767676;
    &.profileLink {
      .icn {
        width: 28px;
        height: 28px;
        background: linear-gradient(91.16deg, #78fa8b 1.03%, #0000ff 15.3%);
      }
    }
  }
  .navbar-toggler {
    filter: brightness(500);
  }
  @media (max-width: 992px) {
    div#basic-navbar-nav {
      padding-top: 10px;
      .navbar-nav {
        > div {
          padding: 10px;
        }
        > div:not(:last-child) {
          border-bottom: 1px dashed #ddd;
        }
      }
    }
  }
`;
export default MainHeader;
const help = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9993 15.8V15.8422M8.60039 8.04535C8.60039 6.69483 9.67491 5.60002 11.0004 5.60002C12.3259 5.60002 13.4004 6.69483 13.4004 8.04535C13.4004 9.39587 12.3259 10.4907 11.0004 10.4907C11.0004 10.4907 10.9993 11.2206 10.9993 12.1209M20.6004 11C20.6004 16.302 16.3023 20.6 11.0004 20.6C5.69846 20.6 1.40039 16.302 1.40039 11C1.40039 5.69809 5.69846 1.40002 11.0004 1.40002C16.3023 1.40002 20.6004 5.69809 20.6004 11Z"
      stroke="#767676"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const link = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.379 10.1905L5.14554 12.424C4.31141 13.2581 3.83173 14.3931 3.8405 15.5859C3.84926 16.7787 4.31844 17.9206 5.19216 18.7673C6.03884 19.641 7.18097 20.1102 8.37359 20.119C9.59342 20.1279 10.7015 19.6753 11.5357 18.8411L13.7692 16.6077M16.622 13.8094L18.8554 11.576C19.6896 10.7418 20.1692 9.60687 20.1605 8.41406C20.1517 7.22124 19.6825 6.07936 18.8088 5.23262C17.9623 4.38614 16.8204 3.91693 15.6276 3.90816C14.4348 3.8994 13.2997 4.35185 12.4655 5.18601L10.232 7.41946M8.61359 15.3271L15.314 8.62676"
      stroke="#767676"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
