import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import forgotImg from "../../../Assets/images/forgotPass.png";

// css
import { InputWithLabel } from "../../../layout/Auth/AuthStyled";
import { useDispatch, useSelector } from "react-redux";
import { login, resetForgotPassword } from "../../../lib/redux";
import { logger } from "../../../helper/common_helper";
import { setAuthInfo } from "../../../helper/api_helper";

const ResetPassword = () => {
  const { loading, ...auth } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handlerSubmit = (event) => {
    event.preventDefault();

    try {
      const { confirmPassword, password } = formValues;

      // Validation check: Ensure the 'name' is not empty
      if (!confirmPassword || !password) {
        toast.error("All fields are required");
        return;
      }

      const callBack = (err, res) => {
        if (err) {
          console.log("Error:", err.message);
          toast.error(err.message);
        } else {
          logger(res, "asdfasdfjasw");
          toast.success(res.message);
          setAuthInfo(res.token, res.data);
          navigate("/login");
        }
      };

      // Dispatch the action to register the user
      dispatch(
        resetForgotPassword({
          data: { confirmPassword, password },
          callBack,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
      toast.error("An error occurred");
    }
  };
  return (
    <>
      <Col lg="5" md="6" className="my-2">
        <div
          className={` formWrpper position-relative `}
          style={{ maxHeight: "85vh" }}
        >
          <div className="formInner position-relative px-lg-3">
            <div className="w-100 inner">
              <div className="top">
                <h2 className="m-0 fw-sbold py-2 text-dark">Set a password</h2>
                <p className="m-0 py-2 fw-normal text-muted">
                  Your previous password has been reseted. Please set a new
                  password for your account.
                </p>
              </div>
              <div className="contentBody py-2">
                <Form onSubmit={handlerSubmit}>
                  <Row>
                    <Col lg="12" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor=""
                          className="form-label position-absolute bg-white px-1"
                        >
                          Create Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          onChange={handleChange}
                          placeholder=""
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>
                    <Col lg="12" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor=""
                          className="form-label position-absolute bg-white px-1"
                        >
                          Re-enter Password
                        </label>
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          onChange={handleChange}
                          type="password"
                          placeholder=""
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>
                    <Col lg="12" className="my-2">
                      <div className="btnWrpper mt-3">
                        <Button
                          loading={loading}
                          type="submit"
                          className="d-flex align-items-center justify-content-center commonBtn w-100"
                        >
                          Set password
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md="6" className="imgWrpper d-none d-lg-block">
        <div className="rounded-3 p-3" style={{ background: "#F4F4F4" }}>
          <img src={forgotImg} alt="" className="img-fluid w-100 rounded-3" />
        </div>
      </Col>
    </>
  );
};
export default ResetPassword;

const backIcn = (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75 14.75L1 8L7.75 1.25"
      stroke="#313131"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
