import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { privateRoutes, publicRoutes, routes } from "./pages/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import AuthLayout from "./layout/Auth/authLayout";
import MainLayout from "./layout/MainLayout/MainLayout";
import WithSidebar from "./layout/WithSidebar";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.Auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Routes>
      {/* Unauthenticated Routes (Login, Signup, etc.) */}
      {!isAuthenticated ? (
        <>
          <Route element={<AuthLayout />}>
            <Route index element={<Navigate to="/landing-page" />} />
            {routes.map((data, index) => (
              <Route
                onUpdate={() => window.scrollTo(0, 0)}
                exact={true}
                path={data.path}
                element={data.component}
                key={index}
              />
            ))}
            <Route path="*" element={<Navigate to="/landing-page" />} />
          </Route>
        </>
      ) : (
        // If authenticated, render private routes
        <Route element={<WithSidebar />}>
          <Route index element={<Navigate to="/dashboard" />} />
          {privateRoutes.map((data, index) => (
            <Route
              onUpdate={() => window.scrollTo(0, 0)}
              exact={true}
              path={data.path}
              element={data.component}
              key={index}
            />
          ))}
          <Route path="*" element={<Navigate to="/landing-page" />} />
        </Route>
      )}

      {/* Public Routes (Landing Page, etc.) */}
      <Route element={<MainLayout />}>
        <Route
          index
          element={
            <Navigate to={isAuthenticated ? "/dashboard" : "/landing-page"} />
          }
        />
        {publicRoutes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={data.component}
            key={index}
          />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
