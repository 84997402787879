import styled from "styled-components";

export const InputWithLabel = styled.div`
  label.form-label {
    top: 0;
    left: 10px;
    transform: translateY(-50%);
    font-size: 12px;
    z-index: 99;
  }
  input.form-control {
    height: 56px !important;
    border: 1px solid #1c1b1f;
  }
`;
