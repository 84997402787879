import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import bg from "../../Assets/images/herosec.png";
import herof from "../../Assets/images/herof.png";

const HeroSec = () => {
  return (
    <>
      <HeroSection className="HeroSec position-relative">
        <img
          src={bg}
          alt=""
          className="img-fluid w-100 h-100 position-absolute object-fit-cover"
        />
        <Container className="position-absolute w-100">
          <Row className="justify-content-center">
            <Col lg="6" md="8" sm="10" className="my-2">
              <BannerContent className=" text-center">
                <h2 className="m-0 text-white fw-light">
                  Your{" "}
                  <div className="rounded d-inline-block fw-normal px-2">
                    <span>Personal AI</span>
                  </div>{" "}
                  Assistant, Tailored to Your Needs
                </h2>
                <p className="m-0 text-white fw-light">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </p>
                <div className="mt-4 btnWrpper d-flex align-items-center justify-content-center gap-10">
                  <Button className="d-flex align-items-center justify-content-center gap-10 border-0">
                    <span className="icn">{demoIcn}</span>
                    Book a demo
                  </Button>
                  <Button className="d-flex align-items-center justify-content-center gap-10 border-0">
                    <span className="icn">{buildAiIcn}</span>
                    Build AI
                  </Button>
                </div>
              </BannerContent>
            </Col>
          </Row>
        </Container>
        <div className="px-3">
          <img src={herof} alt="" className="img-fluid w-100 " />
        </div>
      </HeroSection>
    </>
  );
};

const BannerContent = styled.div`
  h2 {
    font-size: 40px;
    div {
      background: linear-gradient(318.39deg, #355e3b 15.7%, #0f0b41 84.3%);
      border-radius: 8px;
      span {
        background: linear-gradient(93.5deg, #2fca46 24.43%, #8e8eff 69.93%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
  button {
    min-width: 191px;
    height: 60px;
    background: linear-gradient(318.39deg, #355e3b 15.7%, #0f0b41 84.3%);
    border-radius: 6px;
    transition: 0.4s;
    &:hover {
      background: #0b0b3e;
    }
  }
  @media (max-width: 1100px) {
    h2 {
      font-size: 35px;
      padding-bottom: 12px;
    }
    button {
      min-width: 150px;
      height: 50px;
      font-size: 12px;
    }
  }
  @media (max-width: 992px) {
  }
`;

const HeroSection = styled.section`
  padding-top: 112px;
  z-index: 9;
  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(63px);
    z-index: -1;
  }
  > img.position-absolute {
    top: 0;
    left: 0;
    z-index: -1;
    max-height: 100%;
  }
  > div.container.position-absolute {
    top: 20%;
    left: 0;
    right: 0;
  }
  @media (max-width: 1100px) {
    > div.container.position-absolute {
      top: 13%;
    }
  }
  @media (max-width: 992px) {
    > div.container.position-absolute {
      position: unset !important;
    }
  }
`;

export default HeroSec;

const demoIcn = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15Z"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.51953 7.10999H21.4795"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.51953 2.10999V6.96999"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4805 2.10999V6.51999"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.75 14.45V13.25C9.75 11.71 10.84 11.08 12.17 11.85L13.21 12.45L14.25 13.05C15.58 13.82 15.58 15.08 14.25 15.85L13.21 16.45L12.17 17.05C10.84 17.82 9.75 17.19 9.75 15.65V14.45V14.45Z"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const buildAiIcn = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 11L21.2 2.79999"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9999 6.83V2H17.1699"
      stroke="#F1F1F1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
