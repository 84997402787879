import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import signupImg from "../../../Assets/images/signup.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// css
import { InputWithLabel } from "../../../layout/Auth/AuthStyled";
import { useDispatch, useSelector } from "react-redux";
import { registerEmailAndOtpSend } from "../../../lib/redux";
import { setAuthInfo } from "../../../helper/api_helper";
const SignUp = () => {
  const dispatch = useDispatch();
  const { loading, ...auth } = useSelector((state) => state.Auth);

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryCode: "",
    mobileNumber: "",
  });
  const [pass, setPass] = useState(); // Extract loading state from Redux
  const [phone, setPhone] = useState(""); // Full phone number (with country code)
  const [countryCode, setCountryCode] = useState(""); // Country code (e.g., +91)
  const [mobileNumber, setMobileNumber] = useState("");
  const handlePass = () => {
    setPass(!pass);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handlePhoneChange = (value, data) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      countryCode: data?.dialCode,
      mobileNumber: value?.replace(`+${data?.dialCode}`, "").trim(),
      phone: value, // Full phone number
    }));
  };

  const handlerSubmit = (event) => {
    event.preventDefault();

    try {
      // Combine first and last name into one field 'name'
      const name = `${formValues.fname} ${formValues.lname}`.trim();

      const { email, password, confirmPassword, countryCode, mobileNumber } =
        formValues;

      // Validation check: Ensure the 'name' is not empty
      if (
        !name ||
        !email ||
        !password ||
        !confirmPassword ||
        !countryCode ||
        !mobileNumber
      ) {
        toast.error("All fields are required");
        return;
      }

      // Validation check: Ensure passwords match
      if (password !== confirmPassword) {
        toast.error("Passwords do not match");
        return;
      }

      const callBack = (err, res) => {
        if (err) {
          console.log("Error:", err.message);
          toast.error(err.message);
        } else {
          console.log(res);
          setAuthInfo(res.data.authToken, res.data);
          toast.success(res.message);
          navigate("/login"); // Redirect to login after successful registration
        }
      };

      // Dispatch the action to register the user
      dispatch(
        registerEmailAndOtpSend({
          name, // Send the combined name
          email,
          password,
          countryCode,
          mobileNumber,
          confirmPassword,
          callBack,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
      toast.error("An error occurred");
    }
  };

  return (
    <>
      <Col md="5" className="imgWrpper d-none d-lg-block">
        <div className="rounded-3 p-3" style={{ background: "#F4F4F4" }}>
          <img src={signupImg} alt="" className="img-fluid w-100 rounded-3" />
        </div>
      </Col>
      <Col md="6" className="my-2">
        <div
          className={` formWrpper position-relative `}
          style={{ maxHeight: "85vh" }}
        >
          <div className="formInner position-relative px-lg-3">
            <div className="w-100 inner">
              <div className="top">
                <h2 className="m-0 fw-sbold pb-1 text-dark">Sign up</h2>
                <p className="m-0 py-1 fw-normal text-muted">
                  Let’s get you all st up so you can access your personal
                  account.
                </p>
              </div>
              <div className="contentBody py-2">
                <Form onSubmit={handlerSubmit}>
                  <Row>
                    {/* First Name */}
                    <Col md="6" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor="fname"
                          className="form-label position-absolute bg-white px-1"
                        >
                          First Name
                        </label>
                        <input
                          onChange={handleChange}
                          id="fname"
                          name="fname"
                          type="text"
                          placeholder="John"
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>

                    {/* Last Name */}
                    <Col md="6" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor="lname"
                          className="form-label position-absolute bg-white px-1"
                        >
                          Last Name
                        </label>
                        <input
                          onChange={handleChange}
                          id="lname"
                          name="lname"
                          type="text"
                          placeholder="Doe"
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>

                    {/* Email */}
                    <Col md="6" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor="email"
                          className="form-label position-absolute bg-white px-1"
                        >
                          Email
                        </label>
                        <input
                          onChange={handleChange}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="john.doe@gmail.com"
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>

                    {/* Phone */}
                    <Col md="6" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor="phone"
                          className="form-label position-absolute bg-white px-1"
                        >
                          Phone
                        </label>
                        <PhoneInput
                          country="us"
                          value={phone}
                          onChange={handlePhoneChange}
                          inputClass="form-control" // Style it as you want
                        />
                      </InputWithLabel>
                    </Col>

                    {/* Password */}
                    <Col lg="12" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor="password"
                          className="form-label position-absolute bg-white px-1"
                        >
                          Password
                        </label>
                        <input
                          onChange={handleChange}
                          id="password"
                          name="password"
                          type="password"
                          placeholder="••••••••"
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>

                    {/* Confirm Password */}
                    <Col lg="12" className="my-3">
                      <InputWithLabel className="position-relative">
                        <label
                          htmlFor="confirmPassword"
                          className="form-label position-absolute bg-white px-1"
                        >
                          Confirm Password
                        </label>
                        <input
                          onChange={handleChange}
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          placeholder="••••••••"
                          className="form-control bg-white"
                        />
                      </InputWithLabel>
                    </Col>

                    {/* Submit Button */}
                    <Col lg="12" className="my-2">
                      <div className="btnWrpper mt-3">
                        <Button
                          disabled={loading} // Disable button if loading is true
                          type="submit"
                          className="d-flex align-items-center justify-content-center commonBtn w-100"
                        >
                          {loading && (
                            <span className="loading loading-spinner"></span>
                          )}{" "}
                          {/* Show spinner if loading */}
                          {!loading && "Create Account"}
                        </Button>
                        <div className="mt-3 text-center">
                          <p className="m-0">
                            Already have an account?{" "}
                            <Link to="/login" className="themeClr">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};
export default SignUp;
