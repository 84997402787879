import React from "react";
import HeroSec from "./HeroSec";
import OurFeature from "../../components/OurFeature";
import HowItWork from "../../components/HowItWork";
import OurCustomer from "../../components/OurCustomer";

const LandingPage = () => {
  return (
    <>
      <HeroSec />
      <OurFeature />
      <HowItWork />
      <OurCustomer />
    </>
  );
};

export default LandingPage;
