import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import loginImg from "../../../Assets/images/login.png";

// css
import { InputWithLabel } from "../../../layout/Auth/AuthStyled";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  forgotPasswordEmailVerifyOTP,
  verifyForgotEmail,
} from "../../../lib/redux";
import { logger } from "../../../helper/common_helper";
import { setAuthInfo } from "../../../helper/api_helper";
import moment from "moment";
import OTPInput from "react-otp-input";
import styled from "styled-components";

const VerifyCode = () => {
  const { loading, ...auth } = useSelector((state) => state.Auth);
  const location = useLocation();
  const { email } = location.state || {};
  const [otp, setOtp] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlerResend = (event) => {
    event.preventDefault();

    try {
      const callBack = (err, res) => {
        if (err) {
          console.log("Error:", err.message);
          toast.error(err.message);
        } else {
          logger(res, "asdfasdfjasw");
          toast.success(res.message);
          setAuthInfo(res.token, res.data);
        }
      };

      // Dispatch the action to register the user
      dispatch(
        forgotPassword({
          data: { email },
          callBack,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
      toast.error("An error occurred");
    }
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    if (!otp) {
      return toast.error("Please enter otp");
    }
    try {
      const callBack = (err, res) => {
        if (err) {
          toast.error(err.message);
        } else {
          logger(res, "asdfasdfjasw");
          toast.success(res.message);
          setAuthInfo(res.data.passToken);
          navigate("/reset-password");
        }
      };

      dispatch(
        forgotPasswordEmailVerifyOTP({
          data: {
            OTP: otp,
            email,
          },
          callBack,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  // useEffect(() => {
  //   if (!auth?.user?.expireTime) return; // If expireTime is not available, return

  //   const timer = setInterval(() => {
  //     const newRemainingTime = calculateRemainingTime();
  //     setRemainingTime(newRemainingTime);

  //     if (newRemainingTime <= 0) {
  //       clearInterval(timer);
  //     }
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, [auth?.user?.expireTime, calculateRemainingTime, dispatch]);

  return (
    <>
      <Col lg="5" md="6" className="my-2">
        <div
          className={` formWrpper position-relative `}
          style={{ maxHeight: "85vh" }}
        >
          <div className="formInner position-relative px-lg-3">
            <div className="w-100 inner">
              <div className="top">
                <Link
                  to="/login"
                  className="d-inline-flex mb-3 text-dark align-items-center "
                >
                  <span className="icn me-2">{backIcn}</span> Back to login
                </Link>
                <h2 className="m-0 fw-sbold py-2 text-dark">Verify code</h2>
                <p className="m-0 py-2 fw-normal text-muted">
                  An authentication code has been sent to your email.
                </p>
              </div>
              <div className="contentBody py-2">
                <Form onSubmit={handlerSubmit}>
                  <Row>
                    <Col lg="12" className="my-3">
                      {/* <InputWithLabel className="position-relative">
                        <label
                          htmlFor=""
                          className="form-label position-absolute bg-white px-1"
                        >
                          Enter Code
                        </label>
                        <input
                          id="code"
                          name="code"
                          type="text"
                          placeholder=""
                          className="form-control bg-white"
                        />
                      </InputWithLabel> */}
                      <OTPWrp>
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </OTPWrp>
                      {/* {moment(auth?.user?.expireTime).diff(
                        moment(),
                        "seconds"
                      ) > 0 && (
                        <p className="text-white m-0 py-3 text-center">
                          Resend code
                          <span style={{ color: "#4D5DFA", cursor: "pointer" }}>
                            ({formattedTime})
                          </span>
                        </p>
                      )}
                      {moment(auth?.user?.expireTime).diff(
                        moment(),
                        "seconds"
                      ) <= 0 && (
                        <p className="text-white m-0 py-3 text-center">
                          Resend code
                          <span
                            style={{ color: "#4D5DFA", cursor: "pointer" }}
                            // onClick={resendOtp}
                          >
                            click here
                          </span>
                        </p>
                      )} */}
                    </Col>
                    <Col lg="12" className="my-2">
                      <p className="m-0 text-dark">
                        Didn’t receive a code?{" "}
                        <Button
                          type="button"
                          onClick={handlerResend}
                          className="border-0 p-0 themeClr"
                          variant="transparent"
                        >
                          Resend
                        </Button>{" "}
                      </p>
                    </Col>
                    <Col lg="12" className="my-2">
                      <div className="btnWrpper mt-3">
                        <Button
                          type="submit"
                          disabled={loading}
                          className="d-flex align-items-center justify-content-center commonBtn w-100"
                        >
                          Verify
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md="6" className="imgWrpper d-none d-lg-block">
        <div className="rounded-3 p-3" style={{ background: "#F4F4F4" }}>
          <img src={loginImg} alt="" className="img-fluid w-100 rounded-3" />
        </div>
      </Col>
    </>
  );
};

const OTPWrp = styled.div`
  div {
    justify-content: center;
    gap: 10px;
    input {
      height: 50px;
      width: 50px !important;
      border-radius: 8px;
      border: 1px solid #ddd;
    }
  }
`;

export default VerifyCode;

const backIcn = (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75 14.75L1 8L7.75 1.25"
      stroke="#313131"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
